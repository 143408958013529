import { ES_CO } from '@nubank/www-latam-commons/config';

import { getYieldRateFileUrl } from '../../utils/environmentUtils';

function validateDateIsToday(item) {
  // TODO-ANDRES fix the date issue, make sure is persistent
  const itemDate = new Date(`${item}T05:00:00.000Z`);
  const today = new Date();

  if (
    itemDate.getFullYear() === today.getFullYear()
        && itemDate.getMonth() === today.getMonth()
        && itemDate.getDate() === today.getDate()
  ) {
    return true;
  }
  return false;
}

export function getMonthToUpperCase(month) {
  const dateMonth = month.toLocaleString(ES_CO, { month: 'long' });
  return dateMonth.charAt(0).toUpperCase() + dateMonth.slice(1);
}

function parseData(response) {
  if (!validateDateIsToday(response.date)) {
    return null;
  }

  const parsedData = response.yields.map(item => {
    const sinceArray = item.since.split('-');
    return {
      yield_rate: parseFloat(item.yield_rate),
      since: {
        year: parseInt(sinceArray[0], 10),
        month: getMonthToUpperCase(new Date(sinceArray[1])),
        day: parseInt(sinceArray[2], 10),
      },
      raw_since: item.since,
      maturity: parseInt(item.maturity, 10),
    };
  });
  return {
    cajitas: parsedData.find(item => item.maturity === 0),
    cdts: parsedData.filter(item => item.maturity !== 0),
  };
}

export async function getYieldRates() {
  const yieldRateUrl = getYieldRateFileUrl();
  const yieldResponse = await fetch(yieldRateUrl).then(response => response.json());
  return parseData(yieldResponse);
}

export function toYieldRateStringFromFloat(yieldRate) {
  return `${(yieldRate * 100).toFixed(2)}`.replace('.', ',');
}

export const getYieldSince = (maxYield, options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}) => {
  const event = new Date(`${maxYield.raw_since}T05:00`);
  return event.toLocaleDateString('es-CO', options);
};
